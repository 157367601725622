import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

<path d="M3170 4269 l0 -151 -42 37 c-135 115 -350 65 -428 -101 -43 -93 -42
-242 4 -332 47 -93 154 -162 251 -162 56 0 135 30 176 67 l39 35 0 -46 0 -46
80 0 80 0 0 425 0 425 -80 0 -80 0 0 -151z m-75 -223 c151 -116 52 -376 -130
-342 -43 8 -103 59 -121 103 -22 51 -18 133 9 183 47 89 163 116 242 56z"/>
<path d="M4650 3995 l0 -425 80 0 80 0 0 425 0 425 -80 0 -80 0 0 -425z"/>
<path d="M2142 4362 c-100 -36 -152 -110 -152 -214 0 -121 53 -171 243 -232
92 -29 121 -43 141 -67 53 -63 14 -144 -75 -155 -74 -10 -121 19 -143 89 -5
14 -18 17 -90 17 l-85 0 6 -32 c10 -53 20 -74 54 -112 101 -116 338 -125 453
-18 42 40 66 98 66 165 0 116 -55 171 -229 228 -115 37 -145 52 -165 81 -42
60 7 138 86 138 55 0 88 -17 113 -57 20 -32 21 -32 103 -33 93 0 97 5 67 78
-21 50 -51 79 -110 110 -68 35 -204 42 -283 14z"/>
<path d="M3510 4290 l0 -80 -35 0 -35 0 0 -65 0 -65 35 0 35 0 0 -154 c0 -179
11 -247 45 -292 32 -41 101 -64 197 -64 l68 0 0 64 0 64 -57 4 c-90 8 -94 17
-91 212 l3 161 73 3 72 3 0 64 0 65 -75 0 -75 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
<path d="M4123 4205 c-107 -29 -179 -99 -209 -203 -23 -76 -15 -202 14 -264
104 -213 415 -238 549 -44 49 72 47 76 -48 76 -78 0 -81 -1 -112 -34 -66 -70
-187 -53 -235 33 -42 74 -50 71 213 71 l235 0 0 58 c0 226 -186 366 -407 307z
m163 -135 c45 -22 67 -48 78 -92 l6 -28 -155 0 c-101 0 -155 4 -155 10 0 6 9
29 21 51 14 29 32 46 62 60 54 24 91 24 143 -1z"/>
<path d="M5167 4210 c-62 -16 -112 -47 -155 -95 -53 -60 -77 -130 -77 -225 0
-139 60 -241 173 -297 77 -38 199 -39 280 -4 58 26 133 102 150 151 l11 30
-83 0 c-78 0 -84 -1 -111 -30 -78 -82 -213 -52 -250 55 l-13 40 233 3 233 2 5
29 c8 41 -10 146 -33 191 -46 90 -142 148 -257 155 -37 2 -85 0 -106 -5z m134
-130 c44 -12 87 -56 95 -98 l7 -32 -152 0 c-140 0 -151 1 -151 19 0 29 43 85
80 103 40 20 69 22 121 8z"/>
<path d="M5867 4206 c-96 -27 -182 -114 -208 -209 -18 -66 -7 -214 19 -264 34
-66 78 -110 140 -140 49 -24 69 -28 142 -28 143 1 235 58 285 179 l15 36 -89
0 c-82 0 -90 -2 -96 -21 -18 -55 -130 -77 -193 -39 -93 57 -97 267 -7 338 55
43 182 19 200 -37 6 -19 14 -21 95 -21 l90 0 -10 25 c-30 80 -79 133 -153 165
-60 27 -166 34 -230 16z"/>
<path d="M6563 4202 c-110 -39 -176 -106 -207 -212 -22 -72 -20 -134 5 -215
53 -173 240 -255 431 -189 60 20 140 91 167 147 64 133 41 296 -56 393 -81 81
-232 115 -340 76z m194 -148 c53 -40 67 -70 71 -148 3 -59 0 -81 -17 -114 -58
-113 -210 -124 -279 -19 -23 35 -27 51 -27 117 0 66 4 82 26 116 15 23 42 48
60 58 44 22 130 17 166 -10z"/>
<path d="M7401 4211 c-18 -5 -53 -23 -77 -40 l-44 -31 0 35 0 35 -80 0 -80 0
0 -320 0 -320 80 0 80 0 0 203 c0 177 2 207 18 237 48 91 191 93 249 3 16 -23
18 -55 21 -235 l3 -208 80 0 79 0 0 208 c0 190 2 210 20 240 48 78 187 82 237
6 15 -24 19 -57 23 -239 l5 -210 78 -3 78 -3 -3 238 c-3 222 -4 241 -25 279
-79 148 -289 178 -409 59 l-44 -44 -21 28 c-48 65 -178 104 -268 82z"/>
<path d="M8477 4209 c-133 -31 -202 -148 -150 -256 22 -48 70 -76 193 -113
125 -38 150 -52 150 -85 0 -65 -117 -91 -179 -39 -17 14 -31 32 -31 40 0 11
-18 14 -80 14 -76 0 -80 -1 -80 -22 0 -39 44 -102 93 -135 91 -61 223 -67 327
-15 66 33 100 84 100 152 0 93 -50 145 -174 181 -118 34 -159 53 -175 81 -15
25 -14 29 8 52 20 22 34 26 78 26 44 0 58 -5 78 -25 14 -13 25 -31 25 -40 0
-12 14 -15 79 -15 l79 0 -7 33 c-31 133 -172 204 -334 166z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
